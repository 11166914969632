import React, { useEffect, useState } from "react";
import "../../styles/styleCaseDetailPersonaContainer.css";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateField } from "@mui/x-date-pickers";
import Button from "@mui/material/Button";
import axios from "axios";
import dayjs from "dayjs";
import { Alert, Snackbar, LinearProgress, Typography, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import i18nCountries from "i18n-iso-countries";

// Initialize i18n-iso-countries with German translations
i18nCountries.registerLocale(require("i18n-iso-countries/langs/de.json"));

const link = document.createElement("link");
link.href = "https://fonts.googleapis.com/css2?family=Overpass:wght@400&display=swap";
document.head.appendChild(link);

function CaseDetailPersonaContainer({ personData, setPersonData, rowData, selectedStatus, setSelectedStatus, selectedCategory, setSelectedCategory, selectedLabels, setSelectedLabels, selectedAssignee, setSelectedAssignee }) {
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [countriesList, setCountriesList] = useState([]);
  const [selectedCountryCode, setSelectedCountryCode] = useState(personData.location.country || "");
  const [loading, setLoading] = useState(true);
  const [progress, setProgress] = useState(0);
  const [dateError, setDateError] = useState("");

  // Dialog state
  const [dialogOpen, setDialogOpen] = useState(false);
  const [formIncompleteDialogOpen, setFormIncompleteDialogOpen] = useState(false);
  const [fieldErrors, setFieldErrors] = useState({});

  // Track if changes have been made
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);

  // Initial state variables
  const [initialValues, setInitialValues] = useState({
    personData: {},
    selectedStatus: "",
    selectedCategory: "",
    selectedLabels: [],
    selectedAssignee: "",
  });

  useEffect(() => {
    const countryNames = i18nCountries.getNames("de", { select: "official" });
    const countryAlpha3Codes = Object.keys(countryNames);
    const countries = countryAlpha3Codes.map((alpha2) => ({
      alpha3: i18nCountries.alpha2ToAlpha3(alpha2),
      name: countryNames[alpha2],
    }));
    setCountriesList(countries);
  }, []);

  useEffect(() => {
    // Set initial values when personData or related props change
    setInitialValues({
      personData: { ...personData },
      selectedStatus,
      selectedCategory,
      selectedLabels,
      selectedAssignee,
    });
    if (personData.location.country) {
      setSelectedCountryCode(personData.location.country);
    } else {
      // Default to Germany
      setSelectedCountryCode("DEU");
    }
  }, [personData.location.country]);

  useEffect(() => {
    if (!selectedCategory) {
      setSelectedCategory("601");
    }
    if (!selectedAssignee) {
      setSelectedAssignee("22");
    }
  }, [selectedCategory, selectedAssignee]);


  useEffect(() => {
    const loadData = async () => {
      let progressValue = 0;
      const interval = setInterval(() => {
        progressValue += 10;
        setProgress(progressValue);
        if (progressValue >= 100) {
          clearInterval(interval);
        }
      }, 100);

      await new Promise((resolve) => setTimeout(resolve, 1000));

      setLoading(false);
    };

    loadData();
  }, []);

  useEffect(() => {
    // Mark as having unsaved changes if any field is modified
    setHasUnsavedChanges(true);
  }, [personData, selectedStatus, selectedCategory, selectedLabels, selectedAssignee]);

  const handleCountryChange = (event) => {
    const newCountryCode = event.target.value;
    setSelectedCountryCode(newCountryCode);
    setPersonData((prevData) => ({
      ...prevData,
      location: {
        ...prevData.location,
        country: newCountryCode,
      },
    }));
    setHasUnsavedChanges(true);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    const [section, field] = name.split(".");

    // Clear error message if the field is filled
    if (value) {
      setFieldErrors((prevErrors) => ({ ...prevErrors, [field]: undefined }));
    }

    setPersonData((prevData) => ({
      ...prevData,
      [section]: {
        ...prevData[section],
        [field]: value,
      },
    }));
    setHasUnsavedChanges(true);
  };

  const handleGenderChange = (event) => {
    const value = event.target.value;
    const newValue = genderReverseMap[value];

    // Clear gender error if a valid option is selected
    if (newValue) {
      setFieldErrors((prevErrors) => ({ ...prevErrors, gender: undefined }));
    }

    setPersonData((prevData) => ({
      ...prevData,
      personal: {
        ...prevData.personal,
        gender: newValue,
      },
    }));
    setHasUnsavedChanges(true);
  };

  // Date validation logic
  const handleBirthdateChange = (newDate) => {
    let warningMessage = "";
    let formattedDate = "";

    // Clear the birthDate error if a valid date is entered
    if (newDate) {
      setFieldErrors((prevErrors) => ({ ...prevErrors, birthDate: undefined }));
    }

    if (newDate) {
      // Format entered date into DD.MM.YYYY
      formattedDate = newDate.format("DD.MM.YYYY");

      // Parse date to validate using dayjs
      const day = newDate.date();
      const month = newDate.month() + 1; // months are 0-indexed in dayjs
      const year = newDate.year();

      // Current year for comparison
      const currentYear = dayjs().year();

      // Check for valid year range (between 1900 and the current year)
      if (year < 1900 || year > currentYear) {
        warningMessage = `Das Jahr muss zwischen 1900 und ${currentYear} liegen.`;
      }

      // Check if day and month are valid
      if (month < 1 || month > 12) {
        warningMessage = "Ungültiger Monat. Bitte einen Monat zwischen 1 und 12 eingeben.";
      } else if (day < 1 || day > newDate.daysInMonth()) {
        warningMessage = "Ungültiger Tag. Bitte einen Tag eingeben, der in diesem Monat existiert.";
      }

      // Check for date validity
      if (!newDate.isValid()) {
        warningMessage = "Ungültiges Datum. Bitte überprüfen Sie den Tag und den Monat.";
      }
    } else {
      warningMessage = "Ungültiges Datum. Bitte überprüfen Sie das Format.";
    }

    // Set warning and update the form data
    setDateError(warningMessage);  // Set warning message
    setPersonData((prevData) => ({
      ...prevData,
      personal: {
        ...prevData.personal,
        birthDate: formattedDate, // Store even if invalid
      },
    }));

    setHasUnsavedChanges(true);  // Track changes
  };

  const genderMap = {
    "Bitte Anrede auswählen": "Bitte Anrede auswählen",
    "": "0",
    Frau: "1",
    Herr: "2",
    Divers: "3",
  };

  const genderReverseMap = {
    "Bitte Anrede auswählen": "Bitte Anrede auswählen",
    0: "",
    1: "Frau",
    2: "Herr",
    3: "Divers",
  };

  const genderValue = genderMap[personData.personal.gender] || "Anrede";

  // Form validation function
  const formIsValid = () => {
    const { personal } = personData;
    const errors = {};

    if (!personal.firstName) {
      errors.firstName = "Vorname ist erforderlich.";
    }
    if (!personal.lastName) {
      errors.lastName = "Nachname ist erforderlich.";
    }
    if (!personal.birthDate) {
      errors.birthDate = "Geburtsdatum ist erforderlich.";
    }
    if (genderValue === "0" || genderValue === "") {
      errors.gender = "Anrede ist erforderlich.";
    }

    setFieldErrors(errors);  // Set the validation errors for fields
    return Object.keys(errors).length === 0;  // Form is valid if there are no errors
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Prevent form submission if there is a date error
    if (dateError) {
      setAlertOpen(true);
      setAlertMessage("Bitte beheben Sie die Fehler im Formular.");
      return; // Block form submission
    }

    // Prevent from submission If form is incomplete
    if (!formIsValid()) {
      setFormIncompleteDialogOpen(true);
      return;
    }

    try {
      const submissionData = {
        ...personData,
        location: {
          ...personData.location,
          country: personData.location.country || "DEU", // Set "DEU" if country is empty
        },
        statusCode: selectedStatus,
        categoryCode: selectedCategory || "601",
        labels: selectedLabels,
        caseWorkerUserId: selectedAssignee || "22",
      };

      if (rowData) {
        await axios.put(`/case/${rowData.id}`, submissionData, {
          headers: { withCredentials: true },
        });
        setAlertOpen(true);
        setAlertMessage("Daten erfolgreich aktualisiert");
      } else {
        await axios.post("/case", submissionData, {
          headers: { withCredentials: true },
        });
        setPersonData({ personal: {}, location: {} }); // Reset form fields after successful submission
        setSelectedStatus("");
        setSelectedCategory("");
        setSelectedLabels([]);
        setSelectedAssignee("");
        setAlertOpen(true);
        setAlertMessage("Neuer Fall erfolgreich erstellt");
      }
      setHasUnsavedChanges(false); // Reset unsaved changes flag
    } catch (error) {
      console.error("Error submitting form: ", error);
      alert("Error submitting form. Please try again.");
    }
  };

  const handleDiscardClick = () => {
    setDialogOpen(true); // Open the dialog
  };

  const handleDialogClose = (confirm) => {
    setDialogOpen(false); // Close the dialog
    if (confirm) {
      handleDiscard(); // Proceed with discarding
    }
  };

  const handleDiscard = () => {
    if (!rowData) {
      // Reset fields if no data is saved yet
      setPersonData({ personal: {}, location: {} });
      setSelectedStatus("");
      setSelectedCategory("");
      setSelectedLabels([]);
      setSelectedAssignee("");
    } else {
      // Reset unsaved changes flag
      setHasUnsavedChanges(false);
      setPersonData(initialValues.personData);
      setSelectedStatus(initialValues.selectedStatus);
      setSelectedCategory(initialValues.selectedCategory);
      setSelectedLabels(initialValues.selectedLabels);
      setSelectedAssignee(initialValues.selectedAssignee);
    }
  };

  if (loading) {
    return (
      <div className="loadingContainer">
        <div className="progressBarWrapper">
          <LinearProgress className="progressBar" variant="determinate" value={progress} />
        </div>
        <Typography className="progressText">{`Daten werden geladen ${Math.round(progress)}%`}</Typography>
      </div>
    );
  }

  return (
    <form onSubmit={handleSubmit} className="divCDPersonaContainer">
      <div className="sectionPersona">
        <div className="hlPersonData">Personen Daten</div>
        <div className="personDataRow1">
          <TextField className="sBGender" id="sBGender" select label="Anrede" size="small" value={genderValue} onChange={handleGenderChange} error={!!fieldErrors.gender} helperText={fieldErrors.gender} >
            <MenuItem id="sIGender" value="Bitte Anrede auswählen" disabled>
              <em>Bitte Anrede auswählen</em>
            </MenuItem>
            <MenuItem id="sIGender" value="0">
              {" "}
            </MenuItem>
            <MenuItem id="sIGender" value="1">
              Frau
            </MenuItem>
            <MenuItem id="sIGender" value="2">
              Herr
            </MenuItem>
            <MenuItem id="sIGender" value="3">
              Divers
            </MenuItem>
          </TextField>
        </div>
        <div className="personDataRow2">
          <TextField size="small" className="tFPersona" label="Vorname" value={personData.personal.firstName || ""} onChange={handleInputChange} name="personal.firstName" error={!!fieldErrors.firstName} helperText={fieldErrors.firstName} />
          <TextField size="small" className="tFPersona" label="Nachname" value={personData.personal.lastName || ""} onChange={handleInputChange} name="personal.lastName" error={!!fieldErrors.lastName} helperText={fieldErrors.lastName} />
        </div>
        <div className="personDataRow3">
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DateField className="dfBirthDate" id="dfBirthDate" label="Geburtsdatum" value={dayjs(personData.personal.birthDate, "DD.MM.YYYY") || null} onChange={handleBirthdateChange} size="small" format="DD.MM.YYYY" error={!!fieldErrors.birthDate || !!dateError} helperText={fieldErrors.birthDate || dateError} />
          </LocalizationProvider>
          <TextField size="small" className="tFPersona" label="Geburtsname" value={personData.personal.birthName || ""} onChange={handleInputChange} name="personal.birthName" />
          <TextField size="small" className="tFPersona" label="E-Mail" value={personData.personal.emailAddress || ""} onChange={handleInputChange} name="personal.emailAddress" />
        </div>
        {/* add this in the second phase */}
        <div className="personDataRow4" style={{ display: "none" }}>
          <TextField size="small" className="tFPersona" label="Früherer Name - 1" value={personData.personal.previousName1 || ""} onChange={handleInputChange} name="personal.previousName1" />
          <TextField size="small" className="tFPersona" label="Früherer Name - 2" value={personData.personal.previousName2 || ""} onChange={handleInputChange} name="personal.previousName2" />
        </div>
      </div>

      <div className="sectionAddress">
        <div className="hlAddressData">Adresse</div>
        <div className="addressDataRow1">
          <TextField size="small" className="tFAddress" label="Straße" value={personData.location.street || ""} onChange={handleInputChange} name="location.street" />
          <TextField size="small" className="tFAddressHouseNo" id="tFAddressHouseNo" label="Hausnr." value={personData.location.houseNo || ""} onChange={handleInputChange} name="location.houseNo" />
        </div>
        <div className="addressDataRow2">
          <TextField size="small" className="tFAddressZIP" id="tFAddressZIP" label="PLZ" value={personData.location.zip || ""} onChange={handleInputChange} name="location.zip" />
          <TextField size="small" className="tFAddress" label="Ort" value={personData.location.city || ""} onChange={handleInputChange} name="location.city" />
          <TextField size="small" className="tFAddress" label="Land" select value={selectedCountryCode || ""} onChange={handleCountryChange} name="location.country">
            {countriesList.map((country) => (
              <MenuItem key={country.alpha3} value={country.alpha3}>
                {country.name}
              </MenuItem>
            ))}
          </TextField>
        </div>
      </div>

      {/* add this in the second phase */}
      <div className="sectionPreAddress" style={{ display: "none" }}>
        <div className="preAddress1">
          <div className="hlAddressData">Voranschrift-1</div>
          <div className="preAddressDataRow1">
            <TextField size="small" className="tFPreAddress1" label="Straße" />
            <TextField size="small" className="tFPreAddress1HouseNo" id="tFPreAddress1HouseNo" label="Hausnr." />
          </div>
          <div className="preAddressDataRow2">
            <TextField size="small" className="tFPreAddress1ZIP" id="tFPreAddress1ZIP" label="PLZ" />
            <TextField size="small" className="tFPreAddress1" label="Ort" />
            <TextField size="small" className="tFPreAddress1" label="Land" />
          </div>
        </div>
        <div className="preAddress2">
          <div className="hlAddressData">Voranschrift-2</div>
          <div className="preAddressDataRow1">
            <TextField size="small" className="tFPreAddress2" label="Straße" />
            <TextField size="small" className="tFPreAddress2HouseNo" id="tFPreAddress2HouseNo" label="Hausnr." />
          </div>
          <div className="preAddressDataRow2">
            <TextField size="small" className="tFPreAddress2ZIP" id="tFPreAddress2ZIP" label="PLZ" />
            <TextField size="small" className="tFPreAddress2" label="Ort" />
            <TextField size="small" className="tFPreAddress2" label="Land" />
          </div>
        </div>
      </div>

      <div className="sectionPersonDataConfirmation">
        <Button variant="contained" className="cmdSaveCase" size="small" type="submit">
          {rowData ? "Änderung speichern" : "Neuen Fall Erstellen"}
        </Button>
        <Button variant="text" className="cmdDiscardChanges" size="small" onClick={handleDiscardClick}>
          Verwerfen
        </Button>
        <Snackbar open={alertOpen} autoHideDuration={6000} onClose={() => setAlertOpen(false)}>
          <Alert onClose={() => setAlertOpen(false)} severity="success">
            {alertMessage}
          </Alert>
        </Snackbar>
      </div>

      {/* Confirmation Dialog */}
      <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)}>
        <DialogTitle>Änderungen verwerfen?</DialogTitle>
        <DialogContent>
          <Typography>Wenn Sie fortfahren, gehen alle nicht gespeicherten Daten verloren.</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleDialogClose(false)} color="primary">
            Abbrechen
          </Button>
          <Button onClick={() => handleDialogClose(true)} color="secondary">
            Verwerfen
          </Button>
        </DialogActions>
      </Dialog>

      {/* Dialog for incomplete form */}
      <Dialog open={formIncompleteDialogOpen} onClose={() => setFormIncompleteDialogOpen(false)}>
        <DialogTitle>Unvollständige Angaben</DialogTitle>
        <DialogContent>
          <Typography>Bitte füllen Sie alle Pflichtfelder aus, bevor Sie die Änderungen speichern.</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setFormIncompleteDialogOpen(false)} color="primary">
            Schließen
          </Button>
        </DialogActions>
      </Dialog>
    </form>
  );
}

export default CaseDetailPersonaContainer;
