import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import dayjs from "dayjs";
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";
import SearchIcon from "@mui/icons-material/Search";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import CachedIcon from "@mui/icons-material/Cached";
import { ClearIcon } from "@mui/x-date-pickers";
import { IconButton, FormControl, Select, MenuItem, InputLabel, Alert, Snackbar } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import useGetCaseAttribute from "../customHooks/useGetCaseAttributes";
import "../../styles/styleOverviewMenuFilterContainer.css";
import useGetCaseWorkers from "../customHooks/useGetCaseWorkers";
import Button from "@mui/material/Button";
import ChecklistOutlinedIcon from "@mui/icons-material/ChecklistOutlined";

function OverviewMenuFilterContainer({ onFilterChange, onClearFilters, rowsPerPage, onFilterRequestBody }) {
  /* Const section */
  const navigate = useNavigate();
  const navigateToAddNewCases = () => {
    navigate("/caseDetails");
  };
  const [isFilterContainerVisible, setIsFilterContainerVisible] = useState(false);
  const [filterCounter, setFilterCounter] = useState(0);
  const [filterFromDate, setFilterFromDate] = useState(null);
  const [filterToDate, setFilterToDate] = useState(null);
  const { filterStatusOptions, filterCategoryOptions } = useGetCaseAttribute();
  const { filterUserOptions } = useGetCaseWorkers();
  const [filterStatusSelected, setFilterStatusSelected] = useState(filterStatusOptions[0]?.id || "");
  const [filterSelectedCategory, setFilterSelectedCategory] = useState(filterCategoryOptions[0]?.id || "");
  const [filterTeamOptions, setFilterTeamOptions] = useState([]);
  const [filterTeamSelected, setFilterTeamSelected] = useState(filterTeamOptions[0]?.id || "");
  const [filterUserSelected, setFilterUserSelected] = useState(filterUserOptions[0]?.id || "");
  const [alertOpen, setAlertOpen] = useState(false);

  /* Function section */
  const toggleFilterContainer = () => {
    setIsFilterContainerVisible(!isFilterContainerVisible);
  };

  const handleFromDateChange = (date) => {
    setFilterFromDate(date);
  };

  const handleToDateChange = (date) => {
    setFilterToDate(date);
  };

  const handleFilterStatusChange = (event) => {
    setFilterStatusSelected(event.target.value);
  };

  const handleFilterCategoryChange = (event) => {
    setFilterSelectedCategory(event.target.value);
  };

  const handleFilterTeamChange = (event) => {
    setFilterTeamSelected(event.target.value);
  };

  const handleFilterUserChange = (event) => {
    setFilterUserSelected(event.target.value);
  };

  const parseToDate = (date) => {
    if (date) {
      let parsedDate = new Date();
      parsedDate = dayjs(date.toDate()).format("YYYY-MM-DD");
      console.log("ParsedDate = ", parsedDate);
      return parsedDate;
    }
    return null;
  };

  // Get the count of filters and apply filter
  const handleFilterAndRefresh = async () => {
    if (filterFromDate || filterToDate || filterStatusSelected || filterSelectedCategory || filterUserSelected) {
      setFilterCounter(getAppliedFiltersCount());

      // Prepare the request body
      const requestBody = {
        filter: [
          {
            filterOption: "FROM_DATE",
            value: filterFromDate ? parseToDate(filterFromDate) : "",
          },
          {
            filterOption: "TO_DATE",
            value: filterToDate ? parseToDate(filterToDate) : "",
          },
          { filterOption: "STATUS", value: parseInt(filterStatusSelected) },
          { filterOption: "CATEGORY", value: parseInt(filterSelectedCategory) },
          { filterOption: "ASSIGNED_USER", value: parseInt(filterUserSelected) },
        ],
        page: 0,
        pageSize: rowsPerPage,
      };

      try {
        // Perform axios request with credentials in headers
        const response = await axios.post("/overview", requestBody, {
          headers: {
            withCredentials: true,
          },
        });
        if (!response.status) {
          throw new Error("Failed to fetch data");
        }
        console.log("Filter Counter = ", filterCounter);
        const data = await response.data.content;
        onFilterChange(data, response.data.totalElements); // Call the parent component's filter change handler
        setAlertOpen(true); // Show success alert
        onFilterRequestBody(requestBody); // Send requestBody to the parent component
      } catch (error) {
        console.error("Error fetching table rows:", error);
      }
    }
  };

  const getAppliedFiltersCount = () => {
    let count = 0;
    if (filterFromDate) count++;
    if (filterToDate) count++;
    if (filterStatusSelected) count++;
    if (filterSelectedCategory) count++;
    if (filterUserSelected) count++;
    return count;
  };

  const handleFilterToday = async () => {
    const today = new Date().toISOString().split("T")[0];

    const requestBody = {
      filter: [
        {
          filterOption: "FROM_DATE",
          value: today,
        },
        {
          filterOption: "TO_DATE",
          value: today,
        },
      ],
      page: 0,
      pageSize: rowsPerPage,
    };

    try {
      const response = await axios.post(
        "/overview",
          requestBody,
        {
          headers: {
            withCredentials: true,
          },
        }
      );

      if (response.status !== 200) {
        throw new Error("Failed to fetch data");
      }

      const data = response.data.content;
      onFilterChange(data, response.data.totalElements); // Call the parent component's filter change handler
      setAlertOpen(true); // Show success alert
      onFilterRequestBody(requestBody); // Send requestBody to the parent component
    } catch (error) {
      console.error("Error fetching table rows:", error);
    }
  };

  const handleFilterYesterday = async () => {
    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);
    const yesterdayStr = yesterday.toISOString().split("T")[0];

    const requestBody = {
      filter: [
        {
          filterOption: "FROM_DATE",
          value: yesterdayStr,
        },
        {
          filterOption: "TO_DATE",
          value: yesterdayStr,
        },
      ],
      page: 0,
      pageSize: rowsPerPage,
    };

    try {
      const response = await axios.post(
        "/overview",
        requestBody,
        {
          headers: {
            withCredentials: true,
          },
        }
      );

      if (response.status !== 200) {
        throw new Error("Failed to fetch data");
      }

      const data = response.data.content;
      onFilterChange(data, response.data.totalElements); // Call the parent component's filter change handler
      setAlertOpen(true); // Show success alert
      onFilterRequestBody(requestBody); // Send requestBody to the parent component
    } catch (error) {
      console.error("Error fetching table rows:", error);
    }
  };

  const handleFilterLastThreeDays = async () => {
    const today = new Date();
    const threeDaysAgo = new Date();
    threeDaysAgo.setDate(today.getDate() - 3);
    const threeDaysAgoStr = threeDaysAgo.toISOString().split("T")[0];

    const requestBody = {
      filter: [
        {
          filterOption: "FROM_DATE",
          value: threeDaysAgoStr,
        },
        {
          filterOption: "TO_DATE",
          value: today.toISOString().split("T")[0],
        },
      ],
      page: 0,
      pageSize: rowsPerPage,
    };

    try {
      const response = await axios.post(
        "/overview",
          requestBody,
        {
          headers: {
            withCredentials: true,
          },
        }
      );

      if (response.status !== 200) {
        throw new Error("Failed to fetch data");
      }

      const data = response.data.content;
      onFilterChange(data, response.data.totalElements); // Call the parent component's filter change handler
      setAlertOpen(true); // Show success alert
      onFilterRequestBody(requestBody); // Send requestBody to the parent component
    } catch (error) {
      console.error("Error fetching table rows:", error);
    }
  };

  // Handle quick filter - last thirty days
  const handleFilterLastThirtyDays = async () => {
    const today = new Date();
    const thirtyDaysAgo = new Date();
    thirtyDaysAgo.setDate(today.getDate() - 30);
    const thirtyDaysAgoStr = thirtyDaysAgo.toISOString().split("T")[0];

    const requestBody = {
      filter: [
        {
          filterOption: "FROM_DATE",
          value: thirtyDaysAgoStr,
        },
        {
          filterOption: "TO_DATE",
          value: today.toISOString().split("T")[0],
        },
      ],
      page: 0,
      pageSize: rowsPerPage,
    };

    try {
      const response = await axios.post(
        "/overview",
          requestBody,
        {
          headers: {
            withCredentials: true,
          },
        }
      );

      if (response.status !== 200) {
        throw new Error("Failed to fetch data");
      }

      const data = response.data.content;
      onFilterChange(data, response.data.totalElements); // Call the parent component's filter change handler
      setAlertOpen(true); // Show success alert
      onFilterRequestBody(requestBody); // Send requestBody to the parent component
    } catch (error) {
      console.error("Error fetching table rows:", error);
    }
  };

  // Function to handle clearing all filters...
  const handleClearFilters = () => {
    setFilterFromDate(null);
    setFilterToDate(null);
    setFilterStatusSelected("");
    setFilterSelectedCategory("");
    setFilterTeamSelected("");
    setFilterUserSelected("");
    setFilterCounter(0);
    onClearFilters(); // Notify parent to reset allCases
  };

  return (
    <div className="divOverviewMenuFilterContainer">
      <div className="divOverviewButtonContainer">
        <button onClick={navigateToAddNewCases} className="addIconButton">
          <AddIcon />
        </button>
        <button hidden={true}>
          <SearchIcon />
        </button>
        <button onClick={toggleFilterContainer} className="filterIconButton">
          {isFilterContainerVisible ? (
            <div className="filterCloseButton">
              <CloseIcon />
            </div>
          ) : (
            <div className="filterButton">
              <FilterAltOutlinedIcon />
              {filterCounter > 0 && <span className="filterCounter">{filterCounter}</span>}
            </div>
          )}
        </button>
        <button onClick={handleFilterAndRefresh} className="cachedIconButton">
          <CachedIcon />
        </button>
        <button className="textButtons" onClick={handleFilterToday}>
          Heute
        </button>
        <button className="textButtons" onClick={handleFilterYesterday}>
          Gestern
        </button>
        <button className="textButtons" onClick={handleFilterLastThreeDays}>
          Letzten 3 Tage
        </button>
        <button className="textButtons" onClick={handleFilterLastThirtyDays}>
          Letzten 30 Tage
        </button>
        <IconButton className="textButtons" onClick={handleClearFilters}>
          Filter löschen
          <ClearIcon className="textButtonsIcon" />
        </IconButton>
      </div>
      {isFilterContainerVisible && (
        <div className="divOverviewFilterContainer">
          <FormControl className="fcFormControl">
            <InputLabel>Status</InputLabel>
            <Select value={filterStatusSelected} onChange={handleFilterStatusChange}>
              <MenuItem id="fcSelectBoxItems" value="Bitte Status auswählen" disabled>
                <em>Bitte Status auswählen</em>{" "}
              </MenuItem>
              {filterStatusOptions.map((filterStatusOption) => (
                <MenuItem id="fcSelectBoxItems" value={filterStatusOption.id}>
                  {filterStatusOption.text}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl className="fcFormControl">
            <InputLabel>Kategorien</InputLabel>
            <Select value={filterSelectedCategory} onChange={handleFilterCategoryChange}>
              <MenuItem id="fcSelectBoxItems" value="Bitte Kategorie auswählen" disabled>
                <em>Bitte Kategorie auswählen</em>{" "}
              </MenuItem>
              {filterCategoryOptions.map((filterCategoryOption) => (
                <MenuItem id="fcSelectBoxItems" value={filterCategoryOption.id}>
                  {filterCategoryOption.text}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {/*             <FormControl className="fcFormControl">
              <InputLabel>Teams</InputLabel>
              <Select value={filterTeamSelected} onChange={handleFilterTeamChange}>
                <MenuItem id="fcSelectBoxItems" value="Bitte ein Team auswählen" disabled>
                  <em>Bitte ein Team auswählen</em>{" "}
                </MenuItem>
                {filterTeamOptions.map((filterTeamOption) => (
                  <MenuItem id="fcSelectBoxItems" value={filterTeamOption.id}>
                    {filterTeamOption.text}
                  </MenuItem>
                ))}
              </Select>
            </FormControl> */}
          <FormControl className="fcFormControl">
            <InputLabel>Benutzer</InputLabel>
            <Select value={filterUserSelected} onChange={handleFilterUserChange}>
              <MenuItem id="fcSelectBoxItems" value="Bitte ein Benutzer auswählen" disabled>
                <em>Bitte ein Benutzer auswählen</em>{" "}
              </MenuItem>
              {filterUserOptions.map((filterUserOption) => (
                <MenuItem id="fcSelectBoxItems" value={filterUserOption.id}>
                  {filterUserOption.firstName} {filterUserOption.lastName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker className="fcDatePickers" label="Eingangsdatum von" value={filterFromDate} onChange={handleFromDateChange} format="DD.MM.YYYY" />
            <DatePicker className="fcDatePickers" variant="standard" label="Eingangsdatum bis" value={filterToDate} onChange={handleToDateChange} format="DD.MM.YYYY" />
          </LocalizationProvider>

          <Button
              className="cmdSetFilter"
              size="small"
              startIcon={<ChecklistOutlinedIcon className="cmdSetFilterIcon" />}
              onClick={handleFilterAndRefresh} // Open file dialog
          >
            Filter anwenden
          </Button>
          <Snackbar open={alertOpen} autoHideDuration={6000} onClose={() => setAlertOpen(false)}>
            <Alert onClose={() => setAlertOpen(false)} severity="success">
              Filter erfolgreich angewendet!
            </Alert>
          </Snackbar>
        </div>
      )}
    </div>
  );
}

export default OverviewMenuFilterContainer;
