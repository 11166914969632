import { useEffect, useState } from "react";
import axios from "axios";

// Custom hook to fetch data using Axios and manage state
const useGetCaseAttribute = () => {
  // State variables to hold different options
  const [filterStatusOptions, setFilterStatusOptions] = useState([]);
  const [filterCategoryOptions, setFilterCategoryOptions] = useState([]);
  const [filterLabelOptions, setFilterLabelOptions] = useState([]);
  const [actionStatusOptions, setActionStatusOptions] = useState([]);
  const [actionCategoryOptions, setActionCategoryOptions] = useState([]);
  const [actionLabelOptions, setActionLabelOptions] = useState([]);
  const [caseDetailsStatusOptions, setCaseDetailsStatusOptions] = useState([]);
  const [caseDetailsCategoryOptions, setCaseDetailsCategoryOptions] = useState([]);
  const [caseDetailsLabelOptions, setCaseDetailsLabelOptions] = useState([]);
  const [contentTypeOptions, setContentTypeOptions] = useState([]);

  // useEffect hook to fetch data when the token changes
  useEffect(() => {
    // Async function to fetch data
    const getCaseAttributes = async () => {
      try {
        // Axios GET request to fetch data from "/list/codes"
        const response = await axios.get("/list/codes", {
          headers: { withCredentials: true },
        });

        // Extracting options from response data and updating state
        const options = response.data.codes.caseStatus; // Extracting case status options
        setFilterStatusOptions(options); // Setting filter status options state
        setActionStatusOptions(options); // Setting action status options state
        setCaseDetailsStatusOptions(options); // Setting caseDetails status options state

        const categories = response.data.codes.caseCategory; // Extracting case category options
        setFilterCategoryOptions(categories); // Setting filter category options state
        setActionCategoryOptions(categories); // Setting action category options state
        setCaseDetailsCategoryOptions(categories); // Setting caseDetails category options state

        const labels = response.data.codes.label; // Extracting label options
        setFilterLabelOptions(labels); // Setting filter label options state
        setActionLabelOptions(labels); // Setting action label options state
        setCaseDetailsLabelOptions(labels); // Setting caseDetails label options state

        // Extracting and filtering content type options
        const contentTypes = response.data.codes.contentType.filter((type) => [201, 202, 203].includes(type.id));
        setContentTypeOptions(contentTypes); // Setting content type options state
      } catch (error) {
        console.error("Error fetching data:", error); // Logging error if fetch fails
      }
    };

    getCaseAttributes(); // Calling getCaseAttributes function when useEffect runs
  }, []); // useEffect dependency on token, so it refetches when token changes

  // Returning state variables as an object for use in other components
  return {
    filterStatusOptions,
    filterCategoryOptions,
    filterLabelOptions,
    actionStatusOptions,
    actionCategoryOptions,
    actionLabelOptions,
    caseDetailsStatusOptions,
    caseDetailsCategoryOptions,
    caseDetailsLabelOptions,
    contentTypeOptions,
  };
};

export default useGetCaseAttribute; // Exporting the custom hook for use in other components
