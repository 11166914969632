import React, {useEffect, useState} from "react";
import {Checkbox, FormControlLabel} from "@mui/material";
import "../../styles/styleOnlineFormData.css";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {DateField} from "@mui/x-date-pickers";
import Button from "@mui/material/Button";
import axios from "axios";
import dayjs from "dayjs";
import {Snackbar} from "@mui/material";
import i18nCountries from "i18n-iso-countries";
import Person3OutlinedIcon from '@mui/icons-material/Person3Outlined';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import Turnstile from "react-turnstile";

function OnlineFormData() {
    const [genderValue, setGenderValue] = useState("");
    const [personData, setPersonData] = useState({
        personal: {
            gender: "",
            firstName: "",
            lastName: "",
            birthDate: null,
            birthName: "",
            emailAddress: "",
            previousName1: "",
            previousName2: ""
        },
        location: {
            street: "",
            houseNo: "",
            zip: "",
            city: "",
            country: ""
        }
    });
    const [fieldErrors, setFieldErrors] = useState({});
    const [dateError, setDateError] = useState(null);
    const [selectedCountryCode, setSelectedCountryCode] = useState("");
    const [countriesList, setCountriesList] = useState([]);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const [turnstileToken, setTurnstileToken] = useState("");
    const [turnstileResetKey, setTurnstileResetKey] = useState(0);
    const [loading, setLoading] = useState(false);

    // Checkbox state
    const [checkboxes, setCheckboxes] = useState({
        art15: false,
        art16: false,
        contractIssue: false
    });

    useEffect(() => {
        const countryNames = i18nCountries.getNames("de", { select: "official" });
        const countryAlpha3Codes = Object.keys(countryNames);
        const countries = countryAlpha3Codes.map((alpha2) => ({
            alpha3: i18nCountries.alpha2ToAlpha3(alpha2),
            name: countryNames[alpha2],
        }));
        setCountriesList(countries);

        // Check if "Deutschland" is in the list, and set it as the default selected country
        const germany = countries.find(country => country.name === "Deutschland");
        if (germany) {
            setSelectedCountryCode(germany.alpha3);
            setPersonData((prevData) => ({
                ...prevData,
                location: {
                    ...prevData.location,
                    country: germany.alpha3
                }
            }));
        }
    }, []);

    // Handle checkbox change
    const handleCheckboxChange = (event) => {
        const {name, checked} = event.target;
        setCheckboxes((prevCheckboxes) => ({
            ...prevCheckboxes,
            [name]: checked,
        }));
    };

    const handleGenderChange = (event) => {
        const gender = event.target.value;
        setGenderValue(gender);

        setPersonData((prevData) => ({
            ...prevData,
            personal: {
                ...prevData.personal,
                gender: gender // Add gender to personData.personal
            }
        }));

        if (!gender) {
            setFieldErrors((prevErrors) => ({...prevErrors, gender: "Dieses Feld ist erforderlich."}));
        } else {
            setFieldErrors((prevErrors) => ({...prevErrors, gender: undefined}));
        }
    };

    const handleInputChange = (event) => {
        const {name, value} = event.target;

        // Check if the input is part of personal or location data
        if (name in personData.personal) {
            setPersonData((prevData) => ({
                ...prevData,
                personal: {
                    ...prevData.personal,
                    [name]: value
                }
            }));
        } else if (name in personData.location) {
            setPersonData((prevData) => ({
                ...prevData,
                location: {
                    ...prevData.location,
                    [name]: value
                }
            }));
        }

        // Simple validation logic
        if (!value) {
            setFieldErrors((prevErrors) => ({...prevErrors, [name]: "Dieses Feld ist erforderlich."}));
        } else {
            setFieldErrors((prevErrors) => ({...prevErrors, [name]: undefined}));
        }
    };

// Date validation logic
    const handleBirthdateChange = (newDate) => {
        let warningMessage = "";
        let formattedDate = "";

        // Clear the birthDate error if a valid date is entered
        if (newDate) {
            setFieldErrors((prevErrors) => ({ ...prevErrors, birthDate: undefined }));
        }

        if (newDate) {
            // Format entered date into DD.MM.YYYY
            formattedDate = newDate.format("DD.MM.YYYY");

            // Parse date to validate using dayjs
            const day = newDate.date();
            const month = newDate.month() + 1; // months are 0-indexed in dayjs
            const year = newDate.year();

            // Current year for comparison
            const currentYear = dayjs().year();

            // Check for valid year range (between 1900 and the current year)
            if (year < 1900 || year > currentYear) {
                warningMessage = `Das Jahr muss zwischen 1900 und ${currentYear} liegen.`;
            }

            // Check if day and month are valid
            if (month < 1 || month > 12) {
                warningMessage = "Ungültiger Monat. Bitte einen Monat zwischen 1 und 12 eingeben.";
            } else if (day < 1 || day > newDate.daysInMonth()) {
                warningMessage = "Ungültiger Tag. Bitte einen Tag eingeben, der in diesem Monat existiert.";
            }

            // Check for date validity
            if (!newDate.isValid()) {
                warningMessage = "Ungültiges Datum. Bitte überprüfen Sie den Tag und den Monat.";
            }
        } else {
            warningMessage = "Ungültiges Datum. Bitte überprüfen Sie das Format.";
        }

        // Set warning and update the form data
        setDateError(warningMessage);  // Set warning message
        setPersonData((prevData) => ({
            ...prevData,
            personal: {
                ...prevData.personal,
                birthDate: formattedDate, // Store even if invalid
            },
        }));

    };

    const handleCountryChange = (event) => {
        setSelectedCountryCode(event.target.value);
        setPersonData((prevData) => ({
            ...prevData,
            location: {
                ...prevData.location,
                country: event.target.value // Update country in location data
            }
        }));
    };

    const validateForm = () => {
        const errors = {};
        if (!genderValue) errors.gender = "Dieses Feld ist erforderlich.";
        if (!personData.personal.firstName) errors.firstName = "Dieses Feld ist erforderlich.";
        if (!personData.personal.lastName) errors.lastName = "Dieses Feld ist erforderlich.";
        if (!personData.personal.birthDate) errors.birthDate = "Dieses Feld ist erforderlich.";

        // Check location fields
        if (!personData.location.street) errors.street = "Dieses Feld ist erforderlich.";
        if (!personData.location.zip) errors.zip = "Dieses Feld ist erforderlich.";
        if (!personData.location.city) errors.city = "Dieses Feld ist erforderlich.";

        // Update state with errors
        setFieldErrors(errors);

        // Return true if no errors, false if errors exist
        return Object.keys(errors).length === 0;
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        // Prevent form submission if there is a date error
        if (dateError) {
            setSnackbarOpen(true);
            setSnackbarMessage("Bitte beheben Sie die Fehler im Formular.");
            return; // Block form submission
        }

        // Validate the form before submission
        if (!validateForm()) {
            setSnackbarMessage("Bitte alle erforderlichen Felder ausfüllen.");
            setSnackbarOpen(true);
            return; // Do not submit the form if validation fails
        }

        if (!turnstileToken) {
            setSnackbarMessage("Bitte bestätigen Sie, dass Sie kein Roboter sind.");
            setSnackbarOpen(true);
            return;
        }

        setLoading(true); // Start loading

        const genderMap = { 0: "", 1: "Frau", 2: "Herr", 3: "Divers" };
        const mappedGender = genderMap[genderValue] || "";

        const formData = {
            ...personData,
            personal: { ...personData.personal, gender: mappedGender },
            turnstileToken, // Include Turnstile token in the form data
        };

        try {
            const response = await axios.post('/online/form', formData); // Update with your API endpoint

            if (response.status === 201) {
                setSnackbarMessage("Formular erfolgreich eingereicht!");

                // Reset the form to its initial state after a successful submission
                setPersonData({
                    personal: {
                        gender: "",
                        firstName: "",
                        lastName: "",
                        birthDate: null,
                        birthName: "",
                        emailAddress: "",
                        previousName1: "",
                        previousName2: ""
                    },
                    location: {
                        street: "",
                        houseNo: "",
                        zip: "",
                        city: "",
                        country: "DEU"  // Explicitly reset country to Germany
                    }
                });
                setGenderValue("");
                setFieldErrors({});
                setDateError(null);
                setSelectedCountryCode("DEU");  // Keep "Deutschland" selected
                setTurnstileToken("");
                setTurnstileResetKey(prevKey => prevKey + 1); // Update the key to re-render the Turnstile
            }

        } catch (error) {
            setSnackbarMessage("Ein Fehler ist bei der Einreichung des Formulars aufgetreten.");
        } finally {
            setLoading(false); // End loading
            setSnackbarOpen(true);
        }
    };

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };

    return (
        <div className="divOnlineFormDataContainer">
            <div className="divOnlineFormDataHeading">Ihr Anliegen</div>

            <form className="formContainer" onSubmit={handleSubmit}>
                <div className="checkboxContainer" style={{display: "none"}}>
                    <div className="checkboxItem">
                        <FormControlLabel
                            control={
                                <Checkbox
                                    name="art15"
                                    checked={checkboxes.art15}
                                    onChange={handleCheckboxChange}
                                />
                            }
                            label="Art 15 - DSGVO Antrag auf Datenkopie"
                        />
                    </div>
                    <div className="checkboxItem">
                        <FormControlLabel
                            control={
                                <Checkbox
                                    name="art16"
                                    checked={checkboxes.art16}
                                    onChange={handleCheckboxChange}
                                />
                            }
                            label="Art 16 - DSGVO Recht auf Datenbereinigung"
                        />
                    </div>
                    <div className="checkboxItem">
                        <FormControlLabel
                            control={
                                <Checkbox
                                    name="contractIssue"
                                    checked={checkboxes.contractIssue}
                                    onChange={handleCheckboxChange}
                                />
                            }
                            label="Problem bei Vertragsabschluss"
                        />
                    </div>
                </div>

                <div className="sectionUserInfo">
                    <div className="headerUserData">
                        <Person3OutlinedIcon/>
                        <span className="personText">Persönliche Daten</span>
                    </div>
                    <div className="userDataRow1">
                        <TextField
                            className="genderSelect"
                            id="genderSelect"
                            select
                            label="Anrede *"
                            size="small"
                            value={genderValue}
                            onChange={handleGenderChange}
                            error={!!fieldErrors.gender}
                            helperText={fieldErrors.gender}
                        >
                            <MenuItem id="genderOption" value="" disabled>
                                <em>Bitte Anrede auswählen</em>
                            </MenuItem>
                            <MenuItem id="genderOption" value="1">
                                Frau
                            </MenuItem>
                            <MenuItem id="genderOption" value="2">
                                Herr
                            </MenuItem>
                            <MenuItem id="genderOption" value="3">
                                Divers
                            </MenuItem>
                        </TextField>
                    </div>
                    <div className="userDataRow2">
                        <TextField
                            size="small"
                            className="firstNameField"
                            label="Vorname *"
                            value={personData.personal.firstName || ""}
                            onChange={handleInputChange}
                            name="firstName"
                            error={!!fieldErrors.firstName}
                            helperText={fieldErrors.firstName}
                        />
                        <TextField
                            size="small"
                            className="lastNameField"
                            label="Nachname *"
                            value={personData.personal.lastName || ""}
                            onChange={handleInputChange}
                            name="lastName"
                            error={!!fieldErrors.lastName}
                            helperText={fieldErrors.lastName}
                        />
                    </div>
                    <div className="userDataRow3">
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DateField
                                className="birthDateField"
                                id="birthDateField"
                                label="Geburtsdatum *"
                                value={dayjs(personData.personal.birthDate, "DD.MM.YYYY") || null}
                                onChange={handleBirthdateChange}
                                size="small"
                                format="DD.MM.YYYY"
                                error={!!fieldErrors.birthDate || !!dateError}
                                helperText={fieldErrors.birthDate || dateError}
                            />
                        </LocalizationProvider>
                        <TextField
                            size="small"
                            className="birthNameField"
                            label="Geburtsname"
                            value={personData.personal.birthName || ""}
                            onChange={handleInputChange}
                            name="birthName"
                        />
                        <TextField
                            size="small"
                            className="emailField"
                            label="E-Mail"
                            value={personData.personal.emailAddress || ""}
                            onChange={handleInputChange}
                            name="emailAddress"
                        />
                    </div>
                </div>

                <div className="sectionLocationInfo">
                    <div className="headerAddressData">
                        <LocationOnOutlinedIcon/>
                        <span className="addressText">Adresse</span>
                    </div>
                    <div className="addressRow1">
                        <TextField
                            size="small"
                            className="streetField"
                            label="Straße *"
                            value={personData.location.street || ""}
                            onChange={handleInputChange}
                            name="street"
                            error={!!fieldErrors.street}
                            helperText={fieldErrors.street}
                        />
                        <TextField
                            size="small"
                            className="houseNoField"
                            id="houseNoField"
                            label="Hausnr."
                            value={personData.location.houseNo || ""}
                            onChange={handleInputChange}
                            name="houseNo"
                        />
                    </div>
                    <div className="addressRow2">
                        <TextField
                            size="small"
                            className="zipField"
                            id="zipField"
                            label="PLZ *"
                            value={personData.location.zip || ""}
                            onChange={handleInputChange}
                            name="zip"
                            error={!!fieldErrors.zip}
                            helperText={fieldErrors.zip}
                        />
                        <TextField
                            size="small"
                            className="cityField"
                            label="Ort *"
                            value={personData.location.city || ""}
                            onChange={handleInputChange}
                            name="city"
                            error={!!fieldErrors.city}
                            helperText={fieldErrors.city}
                        />
                        <TextField
                            size="small"
                            className="countryField"
                            label="Land"
                            select
                            value={personData.location.country || ""}
                            onChange={handleCountryChange}
                            name="country"
                        >
                            {Array.isArray(countriesList) && countriesList.length > 0 ? (
                                countriesList.map((country) => (
                                    <MenuItem key={country.alpha3} value={country.alpha3}>
                                        {country.name}
                                    </MenuItem>
                                ))
                            ) : (
                                <MenuItem disabled>
                                    <em>Keine Länder verfügbar</em>
                                </MenuItem>
                            )}
                        </TextField>
                    </div>
                </div>

                {/* Turnstile Integration */}
                <div className="turnstile-container">
                    <Turnstile
                        key={turnstileResetKey}
                        sitekey="0x4AAAAAAAxZ9WKF5QijrddQ"
                        onSuccess={(token) => setTurnstileToken(token)}  // Set the token when verified
                        onError={() => setTurnstileToken("")}  // Clear token if Turnstile encounters an error
                        language="de"
                    />
                </div>

                <div className="buttonContainer">
                    <Button className="antragSendenButton" type="submit" variant="contained" color="primary" disabled={loading}>
                        {loading ? "Senden..." : "Antrag auf Selbstauskunft absenden"}
                    </Button>
                </div>
            </form>

            <Snackbar
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={handleSnackbarClose}
                message={snackbarMessage}
            />
        </div>
    );
}

export default OnlineFormData;
