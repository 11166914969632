import { Navigate } from "react-router-dom";
import AuthService from "./AuthService";

const ProtectedRoute = ({ element }) => {
  const isTokenExpired = AuthService.isTokenExpired();
  if (isTokenExpired) {
    // Redirect to login page if not authenticated
    return <Navigate to="/" />;
  }

  // Render the protected component if authenticated
  return element;
};

export default ProtectedRoute;
