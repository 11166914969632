import { createContext, useContext, useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    axios.defaults.withCredentials = true;
  }, []);

  const login = async (username, password) => {
    try {
      const response = await axios.post("/auth/login", { username, password });
      if (response.data.token && response.data.expiresIn) {
        const expiryTime = new Date().getTime() + response.data.expiresIn;
        localStorage.setItem("user", JSON.stringify(response.data));
        localStorage.setItem("tokenExpiry", expiryTime);
        axios.defaults.headers.common["Authorization"] = `Bearer ${response.data.token}`;
        console.log("Login successful. Token expiry set to:", new Date(expiryTime));
        navigate("/overviewTable"); // Navigate to overviewTable on successful login
      } else {
        throw new Error("Invalid response from server");
      }
    } catch (error) {
      console.error("Login failed:", error);
      throw new Error("Login failed");
    }
  };

  const logout = async () => {
    try {
      await axios.get("/auth/logout");
    } catch (error) {
      console.error("Logout error:", error);
    }
    localStorage.removeItem("user");
    localStorage.removeItem("tokenExpiry");
    delete axios.defaults.headers.common["Authorization"];
    setCurrentUser(null);
    navigate("/");
  };

  return <AuthContext.Provider value={{ currentUser, login, logout }}>{children}</AuthContext.Provider>;
};
