import React from "react";
import { DataGrid } from "@mui/x-data-grid";
import { LinearProgress, Typography } from "@mui/material";
import "../../styles/styleOverviewTableContainer.css";
import { useNavigate } from "react-router-dom";
import WarningIcon from "@mui/icons-material/Warning";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";

function OverviewTableContainer({ allCases, onSelectedRowsChange, loading, progress, currentPage, rowsPerPage }) {
  const navigate = useNavigate();
  const caseColumns = [
    {
      field: "entryTs",
      headerName: "Eingangsdatum",
      width: 150,
      sortable: false,
      filterable: false,
    },
    {
      field: "dueDate",
      headerName: "Fälligkeitsdatum",
      width: 150,
      sortable: false,
      filterable: false,
      renderCell: (params) => {
        const dueDate = params.value;
        const status = params.row.statusText;
        let warningIcon = null;

        if (status === `<span id="statusCellDone">DONE</span>`) {
          return <span className="dueDate">{dueDate}</span>;
        }

        if (isInRedRange(dueDate)) {
          warningIcon = <WarningIcon className="warningDueSoon" />;
        } else if (isInOrangeRange(dueDate)) {
          warningIcon = <WarningAmberIcon className="warningComingSoon" />;
        }
        return (
          <div className="dueDataAndWarning">
            <span className="dueDate">{dueDate}</span>
            {warningIcon}
          </div>
        );
      },
    },
    {
      field: "archivId",
      headerName: "Archiv ID",
      width: 155,
      sortable: false,
      filterable: false,
    },
    {
      field: "name",
      headerName: "Name",
      width: 300,
      sortable: false,
      filterable: false,
      renderCell: (params) => <span className="nameColumn">{params.value}</span>,
    },
    {
      field: "address",
      headerName: "Anschrift",
      width: 350,
      sortable: false,
      filterable: false,
    },
    {
      field: "birthDate",
      headerName: "Geburtsdatum",
      width: 120,
      sortable: false,
      filterable: false,
    },
    {
      field: "statusText",
      headerName: "Status",
      width: 150,
      renderCell: (params) => getMatchCellStringFromObject(params.value),
      sortable: false,
      filterable: false,
    },
    {
      field: "caseWorkerUser",
      headerName: "Verantwortlicher",
      width: 150,
      sortable: false,
      filterable: false,
    },
    {
      field: "hitSearchResults",
      headerName: "Match/Treffer",
      width: 150,
      renderCell: (params) => getMatchCellStringFromObject(params.value),
      sortable: false,
      filterable: false,
    },
  ];

  // Combined firstName and lastName into Name column
  const getFullNameValue = (requestPerson) => {
    if (!requestPerson) {
      return ""; // Return an empty string if requestPerson is null or undefined
    }
    return `${requestPerson.firstname || ""} ${requestPerson.lastname || ""}`;
  };

  // Get birthDate from requestPerson into birthDate column
  const getBirthDateValue = (requestPerson) => {
    if (!requestPerson) {
      return ""; // Return an empty string if requestPerson is null or undefined
    }
    return `${requestPerson.birthDate}`;
  };

  // Get the address and combine into one single Anschrift column
  const getAddressValue = (locations) => {
    const location = locations && locations.length > 0 ? locations[0] : {};
    const street = location.street || "";
    const houseNo = location.houseNo || "";
    const zip = location.zip || "";
    const city = location.city || "";

    // Combine street and house number
    const streetAddress = `${street} ${houseNo}`.trim();

    // Combine zip and city
    const zipCity = `${zip} ${city}`.trim();

    // Only include ", " if both streetAddress and zipCity are non-empty
    return streetAddress && zipCity ? `${streetAddress}, ${zipCity}` : streetAddress || zipCity;
  };

  // Get the hitSearchResult into Match/Treffer column
  const getHitValue = (hitResults) => {
    let matchCellValue = "";
    const sortedHits = [];
    const platforms = ["CSP", "TIP", "DSP"];

    hitResults.forEach((hitResult) => {
      if (hitResult) {
        switch (hitResult.platform) {
          case "CSP":
            sortedHits.splice(0, 0, hitResult);
            break;
          case "TIP":
            sortedHits.splice(sortedHits.filter((hit) => hit.platform === "CSP").length, 0, hitResult);
            break;
          case "DSP":
            sortedHits.splice(sortedHits.filter((hit) => hit.platform === "CSP" || hit.platform === "TIP").length, 0, hitResult);
            break;
        }
      }
    });

    platforms.forEach((platform, index) => {
      if (!sortedHits.some((hit) => hit.platform === platform)) {
        sortedHits.splice(index, 0, { platform, placeholder: true });
      }
    });

    if (sortedHits.length > 0) {
      matchCellValue += `<div id="productHitCount">`;

      sortedHits.forEach((hit) => {
        if (hit.matchingInProgress === true) {
          matchCellValue += `<span id="hitPending">${hit.platform}</span>`;
        } else if (hit.counter > 1) {
          matchCellValue += `<span id="multipleHit">${hit.platform}</span> <span id="hitCounter">+</span>`;
        } else if (hit.counter === 1) {
          matchCellValue += `<span id="hit">${hit.platform} </span>`;
        } else if (hit.counter < 1) {
          matchCellValue += `<span id="noHit">${hit.platform} </span>`;
        }
      });
      matchCellValue += `</div>`;
    }

    return matchCellValue;
  };

  // Get the status value and render it
  const getStatusValue = (statusValue) => {
    let statusCellValue = "";

    if (statusValue && statusValue.statusText) {
      if (statusValue.statusText === "Neue Anfrage") {
        statusCellValue = `<span id="statusCellNew">${statusValue.statusText}</span>`;
      }
      if (statusValue.statusText === "Offen") {
        statusCellValue = `<span id="statusCellOpen">${statusValue.statusText}</span>`;
      }
      if (statusValue.statusText === "In Bearbeitung") {
        statusCellValue = `<span id="statusCellInProgress">${statusValue.statusText}</span>`;
      }
      if (statusValue.statusText === "In Klärung") {
        statusCellValue = `<span id="statusCellInInvestigation">${statusValue.statusText}</span>`;
      }
      if (statusValue.statusText === "Bereit zum Senden") {
        statusCellValue = `<span id="statusCellReadyToSend">${statusValue.statusText}</span>`;
      }
      if (statusValue.statusText === "Erledigt") {
        statusCellValue = `<span id="statusCellDone">${statusValue.statusText}</span>`;
      }
    }
    return statusCellValue;
  };

  // Function to format the timestamp to a date string
  const formatDate = (timestamp) => {
    const date = new Date(timestamp);
    return date.toLocaleDateString(undefined, { dateStyle: "medium" });
  };

  // Adjust rows mapping to include the hitResult as a string
  const rows = allCases.map((caseItem) => ({
    id: caseItem.id,
    entryTs: formatDate(caseItem.entryTs),
    dueDate: caseItem.dueDate,
    archivId: caseItem.archivId,
    name: getFullNameValue(caseItem.requestPerson),
    address: getAddressValue(caseItem.requestLocations),
    birthDate: getBirthDateValue(caseItem.requestPerson),
    statusText: getStatusValue(caseItem),
    caseWorkerUser: caseItem.caseWorkerUser,
    hitSearchResults: getHitValue(caseItem.searchResults),
  }));

  // Convert from Object to html string
  function getMatchCellStringFromObject(value) {
    return <div dangerouslySetInnerHTML={{ __html: value }} />;
  }

  // Function to handle row click
  const handleRowClick = (params) => {
    navigate("/caseDetails", { state: { rowData: params.row, currentPage, rowsPerPage } });
  };

  // Function to parse date in DD.MM.YYYY format
  const parseDate = (dateString) => {
    // Check if dateString is null or empty
    if (!dateString) {
      return "";  // Return an empty string if dateString is falsy
    }

    const [day, month, year] = dateString.split(".");
    return new Date(year, month - 1, day);  // This will correctly return a Date object
  };

// Check if the due date is within the red range
  const isInRedRange = (dueDate) => {
    const due = parseDate(dueDate);

    // Ensure due is a valid Date object
    if (!(due instanceof Date) || isNaN(due)) {
      return true;  // Return false if due is not a valid date
    }

    const now = new Date();
    now.setHours(0, 0, 0, 0);  // Set time to midnight for today

    // Red range: The due date and the 5 days before it
    const startRedRange = new Date(due);
    startRedRange.setDate(due.getDate() - 5);  // Get 5 days before the due date

    const endRedRange = due;  // The end of the red range is the due date itself

    // If the due date is before today, it's red
    if (endRedRange < now) {
      return true;
    }

    return now >= startRedRange && now <= endRedRange;  // Check if within the red range
  };

  // Check if the due date is within the orange range
  const isInOrangeRange = (dueDate) => {
    const due = parseDate(dueDate);
    const now = new Date();
    now.setHours(0, 0, 0, 0);

    // Orange range: 10 to 6 days before the due date
    const startOrangeRange = new Date(due);
    startOrangeRange.setDate(due.getDate() - 10);

    const endOrangeRange = new Date(due);
    endOrangeRange.setDate(due.getDate() - 6);

    // Orange range only applies to future due dates
    if (endOrangeRange < now) {
      return false;
    }

    return now >= startOrangeRange && now <= endOrangeRange;
  };

  const handleSelectionModelChange = (selectionModel) => {
    const selectedIDs = new Set(selectionModel);
    const selectedRows = rows.filter((row) => selectedIDs.has(row.id));
    onSelectedRowsChange(selectedRows);
  };

  return (
    <div className="divOverviewTableContainer">
      {loading ? (
        <div className="loadingContainer">
          <div className="progressBarWrapper">
            <LinearProgress className="progressBar" variant="determinate" value={progress} />
          </div>
          <Typography className="progressText">{`Daten werden geladen ${Math.round(progress)}%`}</Typography>
        </div>
      ) : (
        <DataGrid rows={rows} className="data-grid" columns={caseColumns} getRowId={(row) => row.id || row.tdEntryDate} checkboxSelection rowHeight={40} onRowDoubleClick={handleRowClick} disableRowSelectionOnClick hideFooter onRowSelectionModelChange={handleSelectionModelChange} />
      )}
    </div>
  );
}

export default OverviewTableContainer;
