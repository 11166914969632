import React, { useState } from "react";
import { Button, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import UpdateOutlinedIcon from "@mui/icons-material/UpdateOutlined";
import "../../styles/styleCaseDetailHitSelectionContainer.css";

function CaseDetailHitSelectionContainer({ selectedItems, onSelectionChange, onRefresh }) {
  const [openDialog, setOpenDialog] = useState(false);

  // Open the confirmation dialog
  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  // Close the confirmation dialog
  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  // Confirm the refresh action
  const handleConfirmRefresh = () => {
    onRefresh(selectedItems); // Pass the selectedItems state to the onRefresh function
    handleCloseDialog();
  };

  // Toggle selection state
  const handleToggle = (item) => (event) => {
    onSelectionChange(item, event.target.checked);
  };

  return (
    <div className="caseDetailHitSelectionContainer">
      <div className="hlProductHitList">Datenquelle auswählen</div>
      <List className="productHitList">
        {/* Add CSP later in the second phase */}
        <ListItem className="productHitListItem">
          <ListItemButton dense>
            <ListItemIcon>
              <Checkbox edge="start" tabIndex={-1} disableRipple checked={selectedItems.csp} onChange={handleToggle("csp")} />
            </ListItemIcon>
            <ListItemText className="productHitListItemText" primary={`CSP`} />
          </ListItemButton>
        </ListItem>
        <ListItem className="productHitListItem">
          <ListItemButton dense>
            <ListItemIcon>
              <Checkbox edge="start" tabIndex={-1} disableRipple checked={selectedItems.tip} onChange={handleToggle("tip")} />
            </ListItemIcon>
            <ListItemText className="productHitListItemText" primary={`TIP`} />
          </ListItemButton>
        </ListItem>
        <ListItem className="productHitListItem">
          <ListItemButton dense>
            <ListItemIcon>
              <Checkbox edge="start" tabIndex={-1} disableRipple checked={selectedItems.dsp} onChange={handleToggle("dsp")} />
            </ListItemIcon>
            <ListItemText className="productHitListItemText" primary={`DSP`} />
          </ListItemButton>
        </ListItem>
      </List>
      <Button className="anfrageAktualisieren" size="small" startIcon={<UpdateOutlinedIcon />} onClick={handleOpenDialog}>
        Anfrage aktualisieren
      </Button>

      {/* Confirmation Dialog */}
      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>Bestätigung</DialogTitle>
        <DialogContent>
          <DialogContentText>Möchten Sie eine neue Suche starten?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            Abbrechen
          </Button>
          <Button onClick={handleConfirmRefresh} color="primary">
            Bestätigen
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default CaseDetailHitSelectionContainer;
