import React, { useState, useEffect } from "react";
import Box from "@mui/joy/Box";
import Button from "@mui/joy/Button";
import FormControl from "@mui/joy/FormControl";
import Textarea from "@mui/joy/Textarea";
import axios from "axios";

import "../../styles/styleCaseDetailCommentContainer.css";
import useGetCaseWorkers from "../customHooks/useGetCaseWorkers";

function CaseDetailCommentContainer({ rowData, comments, setComments }) {
  // State variable to manage new comment input
  const [newComment, setNewComment] = useState("");

  // hooks
  const { caseDetailsAssigneeOptions } = useGetCaseWorkers();

  // Function to send new comment to backend server
  const sendCommentToBackend = async (comment) => {
    const commentBody = { text: comment }; // Create object with comment text
    try {
      // Axios POST request to send comment to specific case endpoint
      const response = await axios.post(`/comment/${rowData.id}`, commentBody, {
        headers: {
          withCredentials: true,
          AcceptLanguage: "de", // Specify accepted language
        },
      });

      console.log("Response:", response.data); // Log response from server

      // Extract creation timestamp and text from server response
      const { text, creationTms, lastChangeUserId } = response.data;

      // Update comments with server-provided data
      const updatedComments = [...comments, { text, creationTms, lastChangeUserId }];
      setComments(updatedComments); // Update state with new comment and server data
      setNewComment(""); // Clear the input field after adding comment
    } catch (error) {
      // Handle error
      console.error("Error:", error);
    }
  };

  // Function to handle adding a new comment
  const handleAddComment = async () => {
    if (newComment.trim() !== "") {
      await sendCommentToBackend(newComment); // Send the new comment to the backend and wait for response
    }
  };

  // Sort comments by creationTms in descending order
  const sortedComments = [...comments].sort((a, b) => {
    const formatDate = (dateString) => {
      const [date, time] = dateString.split(" ");
      const [day, month, year] = date.split(".");
      return new Date(`${year}-${month}-${day}T${time}`);
    };

    return formatDate(b.creationTms) - formatDate(a.creationTms);
  });

  // Function to get the name of the user from caseDetailsAssigneeOptions
  const getUserName = (userId) => {
    const user = caseDetailsAssigneeOptions.find((user) => user.id === userId);
    return user ? `${user.firstName} ${user.lastName}` : "Unbekannt";
  };

  return (
    <div className="caseDetailCommentContainer">
      <div className="newComment">
        <FormControl>
          <Textarea
            placeholder="Hier bitte einen Kommentar hinzufügen"
            minRows={3}
            value={newComment} // Link Textarea value to state
            onChange={(e) => setNewComment(e.target.value)} // Update state on input change
            endDecorator={
              <Box className="addComment">
                <Button className="cmdAddComment" onClick={handleAddComment}>
                  Hinzufügen
                </Button>
              </Box>
            }
          />
        </FormControl>
      </div>
      <div className="comments">
        {sortedComments.map((comment, index) => (
          <Textarea
            key={index}
            className="textAreaComments"
            value={comment.text}
            disabled
            startDecorator={
              <Box className="displayComments">
                <div className="commentUser">Erstellt von: {comment.lastChangeUserId ? getUserName(comment.lastChangeUserId) : "Unbekannt"}</div>
                <div className="commentDate">Erstellt am: {comment.creationTms}</div>
              </Box>
            }
          />
        ))}
      </div>
    </div>
  );
}

export default CaseDetailCommentContainer;
