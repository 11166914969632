import React from "react";
import "../../styles/styleOnlineFormHeader.css";

function OnlineFormHeader() {
    return (
        <div className= "divOnlineFormHeaderRootContainer">
            <div className="divOnlineFormHeader">
                Anfrage für Datenkopie
            </div>

            {/* Flexbox container to align second and third divs in one line */}
            <div className="flex-container">
                <div className="divOnlineFormHeaderTextOne">
                    ZUR BEAUSKUNFTUNG DER VON DER CRIF GMBH ZU IHRER PERSON GESPEICHERTEN DATEN BENÖTIGEN WIR DIE ANGABE IHRER DATEN.
                </div>

                <div className="divOnlineFormHeaderTextTwo">
                    ZUR EINWANDFREIEN IDENTIFIKATION FÜLLEN SIE BITTE VOLLSTÄNDIG DIE NACHFOLGENDEN EINGABEZEILEN AUS:
                </div>
            </div>
        </div>
    );
}

export default OnlineFormHeader;
