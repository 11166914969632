import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { IconButton, LinearProgress, Typography } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import PictureAsPdfOutlinedIcon from "@mui/icons-material/PictureAsPdfOutlined";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import "../../styles/styleCaseDetailMenuContainer.css";
import useGetCaseAttributes from "../customHooks/useGetCaseAttributes";
import useGetCaseWorkers from "../customHooks/useGetCaseWorkers";
import axios from "axios";
import { saveAs } from "file-saver";

function CaseDetailMenuContainer({
  selectedStatus,
  setSelectedStatus,
  selectedCategory,
  setSelectedCategory,
  selectedLabels,
  setSelectedLabels,
  selectedAssignee,
  setSelectedAssignee,
  selectedRows,
  rowData,
  rowsPerPage,
  currentPage,
  dspLastMatchedDate,
  tipLastMatchedDate,
  cspLastMatchedDate,
  dspReportCreationDate,
  tipReportCreationDate,
  cspReportCreationDate,
}) {
  const navigate = useNavigate();

  // State for handling progress and uploading status
  const [uploading, setUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [progressMessage, setProgressMessage] = useState("");

  // hooks
  const { caseDetailsStatusOptions, caseDetailsCategoryOptions, caseDetailsLabelOptions } = useGetCaseAttributes();
  const { caseDetailsAssigneeOptions } = useGetCaseWorkers();

  // handle changes to the values
  const handleCaseDetailsStatusChange = (event) => {
    setSelectedStatus(event.target.value);
  };

  const handleCaseDetailsCategoryChange = (event) => {
    setSelectedCategory(event.target.value);
  };

  const handleCaseDetailsLabelChange = (event) => {
    const selectedIds = event.target.value;
    const newSelectedLabels = caseDetailsLabelOptions.filter((option) => selectedIds.includes(option.id));
    setSelectedLabels(newSelectedLabels);
  };

  const handleCaseDetailsAssigneeChange = (event) => {
    setSelectedAssignee(event.target.value);
  };

  // Navigate back button to Overview page
  const handleBackClickEvent = () => {
    navigate("/overviewTable", { state: { rowsPerPage, currentPage } });
  };

  // Get the ids of the selected labels for the Select component
  const selectedLabelIds = selectedLabels.map((label) => label.id);

  // Function to generate PDF from backend
  const generatePdf = async () => {
    try {
      const reqPerPlatform = [];

      if (selectedRows.DSP && selectedRows.DSP.length > 0) {
        selectedRows.DSP.forEach((row) => {
          reqPerPlatform.push({
            platform: "DSP",
            selectedIdentifier: row.id,
            refresh: dspLastMatchedDate > dspReportCreationDate,
          });
        });
      }

      if (selectedRows.TIP && selectedRows.TIP.length > 0) {
        selectedRows.TIP.forEach((row) => {
          reqPerPlatform.push({
            platform: "TIP",
            selectedIdentifier: row.id,
            refresh: tipLastMatchedDate > tipReportCreationDate,
          });
        });
      }

      if (selectedRows.CSP && selectedRows.CSP.length > 0) {
        selectedRows.CSP.forEach((row) => {
          reqPerPlatform.push({
            platform: "CSP",
            selectedIdentifier: row.id,
            refresh: cspLastMatchedDate > cspReportCreationDate,
          });
        });
      }

      const jsonParams = {
        docFormat: "PDF",
        reqPerPlatform: reqPerPlatform,
      };

      const encodedParams = new URLSearchParams({
        genRequest: JSON.stringify(jsonParams),
      }).toString();

      setProgressMessage("PDF wird generiert..."); // Set progress message in German
      setUploading(true); // Show the progress bar

      const response = await axios.get(`/case/${rowData.id}/generate?${encodedParams}`, {
        headers: {
          withCredentials: true,
        },
        responseType: "blob", // Ensure response is treated as blob
        onDownloadProgress: (progressEvent) => {
          const { loaded, total } = progressEvent;
          if (total > 0) {
            setUploadProgress(Math.round((loaded / total) * 100));
          }
        },
      });

      const blob = new Blob([response.data], { type: "application/pdf" });
      const url = window.URL.createObjectURL(blob);
      window.open(url); // Open PDF in a new tab
      setUploadProgress(100);
      setUploading(false); // Hide the progress bar after the PDF is generated
    } catch (error) {
      console.error("Error fetching PDF: ", error);
      setUploading(false); // Hide the progress bar in case of error
    }
  };

  // Function to generate Word document from backend
  const generateWord = async () => {
    try {
      const reqPerPlatform = [];

      if (selectedRows.DSP && selectedRows.DSP.length > 0) {
        selectedRows.DSP.forEach((row) => {
          reqPerPlatform.push({
            platform: "DSP",
            selectedIdentifier: row.id,
            refresh: dspLastMatchedDate > dspReportCreationDate,
          });
        });
      }

      if (selectedRows.TIP && selectedRows.TIP.length > 0) {
        selectedRows.TIP.forEach((row) => {
          reqPerPlatform.push({
            platform: "TIP",
            selectedIdentifier: row.id,
            refresh: tipLastMatchedDate > tipReportCreationDate,
          });
        });
      }

      if (selectedRows.CSP && selectedRows.CSP.length > 0) {
        selectedRows.CSP.forEach((row) => {
          reqPerPlatform.push({
            platform: "CSP",
            selectedIdentifier: row.id,
            refresh: cspLastMatchedDate > cspReportCreationDate,
          });
        });
      }

      const jsonParams = {
        docFormat: "RTF",
        reqPerPlatform: reqPerPlatform,
      };

      const encodedParams = new URLSearchParams({
        genRequest: JSON.stringify(jsonParams),
      }).toString();

      setProgressMessage("RTF wird generiert..."); // Set progress message in German
      setUploading(true); // Show the progress bar

      const response = await axios.get(`/case/${rowData.id}/generate?${encodedParams}`, {
        headers: {
          withCredentials: true,
        },
        responseType: "blob", // Ensure response is treated as blob
        onDownloadProgress: (progressEvent) => {
          const { loaded, total } = progressEvent;
          if (total > 0) {
            setUploadProgress(Math.round((loaded / total) * 100));
          }
        },
      });

      // Determine the appropriate file extension
      /* const totalSelected = (selectedRows.DSP ? selectedRows.DSP.length : 0) + (selectedRows.TIP ? selectedRows.TIP.length : 0); */
      const fileExtension = "zip";
      const blobType = "application/zip";
      const fileName = `SD-Report-${rowData.id}.${fileExtension}`;

      const blob = new Blob([response.data], { type: blobType });
      saveAs(blob, fileName); // Save the document using FileSaver.js
      setUploadProgress(100);
      setUploading(false); // Hide the progress bar after the RTF is generated
    } catch (error) {
      console.error("Error fetching Word document: ", error);
      setUploading(false); // Hide the progress bar in case of error
    }
  };

  return (
    <div className="divCDMenuContainer">
      <div className="buttonElements">
        <IconButton className="cmdBackToOverview" onClick={handleBackClickEvent}>
          <ArrowBackIcon />
        </IconButton>
      </div>
      <div className="actionElements">
        <FormControl variant="standard" className="formControls" size="small">
          <InputLabel id="tfSelect">Status</InputLabel>
          <Select labelId="tfSelect" value={selectedStatus} onChange={handleCaseDetailsStatusChange}>
            <MenuItem id="selectItems" value="Bitte Status auswählen" disabled>
              <em>Bitte Status auswählen</em>{" "}
            </MenuItem>
            {caseDetailsStatusOptions.map((caseDetailsStatusOption) => (
              <MenuItem key={caseDetailsStatusOption.id} value={caseDetailsStatusOption.id}>
                {caseDetailsStatusOption.text}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl variant="standard" className="formControls" size="small">
          <InputLabel id="tfSelect">Kategorie</InputLabel>
          <Select labelId="tfSelect" value={selectedCategory} onChange={handleCaseDetailsCategoryChange}>
            <MenuItem id="selectItems" value="Bitte Kategorie auswählen" disabled>
              <em>Bitte Kategorie auswählen</em>{" "}
            </MenuItem>
            {caseDetailsCategoryOptions.map((caseDetailsCategoryOption) => (
              <MenuItem key={caseDetailsCategoryOption.id} value={caseDetailsCategoryOption.id}>
                {caseDetailsCategoryOption.text}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl variant="standard" className="formControls" size="small">
          <InputLabel id="tfSelect">Label</InputLabel>
          <Select multiple labelId="tfSelect" value={selectedLabelIds} onChange={handleCaseDetailsLabelChange}>
            <MenuItem id="selectItems" value="Bitte Label auswählen" disabled>
              <em>Bitte Label auswählen</em>{" "}
            </MenuItem>
            {caseDetailsLabelOptions.map((caseDetailsLabelOption) => (
              <MenuItem key={caseDetailsLabelOption.id} value={caseDetailsLabelOption.id}>
                {caseDetailsLabelOption.text}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl variant="standard" className="formControls" size="small">
          <InputLabel id="tfSelect">Assignee</InputLabel>
          <Select labelId="tfSelect" value={selectedAssignee} onChange={handleCaseDetailsAssigneeChange}>
            <MenuItem id="selectItems" value="Bitte Assignee auswählen" disabled>
              <em>Bitte Assignee auswählen</em>{" "}
            </MenuItem>
            {caseDetailsAssigneeOptions.map((caseDetailsAssigneeOption) => (
              <MenuItem key={caseDetailsAssigneeOption.id} value={caseDetailsAssigneeOption.id}>
                {caseDetailsAssigneeOption.firstName} {caseDetailsAssigneeOption.lastName}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>

      <div className="reportElements">
        <IconButton className="cmdCreatePDFReport" onClick={generatePdf}>
          <PictureAsPdfOutlinedIcon />
        </IconButton>
        <IconButton className="cmdCreateRTFReport" onClick={generateWord}>
          <InsertDriveFileOutlinedIcon />
        </IconButton>
      </div>

      {/* Progress container */}
      {uploading && (
        <div className="excelLoadingContainer">
          <div className="excelProgressBarWrapper">
            <LinearProgress className="excelProgressBar" variant="determinate" value={uploadProgress} />
          </div>
          <Typography className="excelProgressText">
            {progressMessage} {Math.round(uploadProgress)}%
          </Typography>
        </div>
      )}
    </div>
  );
}

export default CaseDetailMenuContainer;
