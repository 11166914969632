import React, { useState, useEffect, useRef, useCallback } from "react";
import { IconButton, List, ListItem, ListItemIcon, ListItemText, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Snackbar, Alert, Button, Select, MenuItem, InputLabel, FormControl } from "@mui/material";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import axios from "axios";
import "../../styles/styleCaseDetailDocumentContainer.css";
import useGetCaseAttributes from "../customHooks/useGetCaseAttributes";

function CaseDetailDocumentContainer({ rowData }) {
  // use custom hook
  const { contentTypeOptions } = useGetCaseAttributes();

  // State for uploaded files
  const [uploadedFiles, setUploadedFiles] = useState([]);

  // State for handling confirmation dialog for deletion
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);

  // State for handling snackbar notifications
  const [openSnackbar, setOpenSnackbar] = useState(false);

  // State for storing the ID of the selected file to delete
  const [selectedFileId, setSelectedFileId] = useState(null);

  // State for handling the "file already exists" dialog
  const [fileExists, setFileExists] = useState(false);

  // State for handling the file size error dialog
  const [fileSizeError, setFileSizeError] = useState(false);

  // State for handling the content type error dialog
  const [contentTypeError, setContentTypeError] = useState({
    open: false,
    message: "",
  });

  // State for storing the selected content type
  const [selectedContentType, setSelectedContentType] = useState("");

  // Ref for accessing the file input element
  const fileInputRef = useRef(null);

  // Function to fetch case details from API
  const getCaseDetails = useCallback(async () => {
    try {
      if (!rowData || !rowData.id) return;

      const response = await axios.get(`/case/${rowData.id}`, {
        headers: { withCredentials: true },
      });

      // Set the initial list of uploaded files
      const initialFiles =
        response.data.caseBlobs?.map((blob) => ({
          id: blob.id,
          filename: blob.filename,
          documentTypeCode: blob.documentTypeCode,
        })) || [];
      setUploadedFiles(initialFiles);
    } catch (error) {
      console.error("Error fetching case details:", error);
    }
  }, [rowData]);

  // Fetch case details when rowData changes
  useEffect(() => {
    getCaseDetails();
  }, [getCaseDetails]);

  // Handle file change event
  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (!file || !selectedContentType) return;

    // Check file size (10MB limit)
    if (file.size > 10 * 1024 * 1024) {
      setFileSizeError(true);
      // Reset file input value to allow re-selection
      event.target.value = null;
      return;
    }

    // Check if the file is already uploaded
    if (uploadedFiles.some((uploadedFile) => uploadedFile.filename === file.name)) {
      setFileExists(true);
      // Reset file input value to allow re-selection
      event.target.value = null;
      return;
    }

    const formData = new FormData();
    formData.append("file", file);

    try {
      // Upload the file to the server
      const response = await axios.post(`/case/${rowData.id}/${parseInt(selectedContentType)}/upload`, formData, {
        headers: {
          withCredentials: true,
          "Content-Type": "multipart/form-data",
        },
      });

      if (response.status === 200) {
        // Refresh the list of uploaded files
        getCaseDetails();
      }
    } catch (error) {
      console.error("Error uploading file:", error);

      if (error.response.status === 400) {
        // Find the text for the selected content type
        const selectedContentTypeText = contentTypeOptions.find((option) => option.id === selectedContentType)?.text || "unbekannter Typ";

        // Set content type error with the text
        setContentTypeError({
          open: true,
          message: `Dokument mit ausgewähltem Inhalt '${selectedContentTypeText}' ist bereits vorhanden`,
        });
      }
    } finally {
      // Reset file input value after handling file
      event.target.value = null;
      // Reset selected content type to default after upload
      setSelectedContentType("");
    }
  };

  // Handle file deletion
  const handleDeleteFile = async () => {
    try {
      await axios.delete(`/blob/${selectedFileId}`, {
        headers: { withCredentials: true },
      });

      // Remove the file from the state without refreshing
      setUploadedFiles((prevFiles) => prevFiles.filter((file) => file.id !== selectedFileId));

      setOpenConfirmDialog(false);
      setOpenSnackbar(true);
    } catch (error) {
      console.error("Error deleting file:", error);
    }
  };

  // Open confirmation dialog for file deletion
  const handleConfirmDelete = (fileId) => {
    setSelectedFileId(fileId);
    setOpenConfirmDialog(true);
  };

  // Trigger file input click
  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  // Close snackbar notification
  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  // Close "file already exists" dialog
  const handleCloseFileExistsDialog = () => {
    setFileExists(false);
  };

  // Close file size error dialog
  const handleCloseFileSizeErrorDialog = () => {
    setFileSizeError(false);
  };

  // Handle closing the content type error dialog
  const handleCloseContentTypeErrorDialog = () => {
    setContentTypeError((prevState) => ({
      ...prevState,
      open: false,
    }));

    // Ensure state change is applied immediately
    setTimeout(() => {
      setContentTypeError((prevState) => ({
        ...prevState,
        message: "",
      }));
    }, 0);
  };

// Define allowed MIME types
  const allowedMimeTypes = [
    "application/pdf",
    "image/jpeg",
    "image/png",
    "application/msword",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    "application/vnd.ms-excel",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  ];

// Handle file click
  const handleFileClick = async (fileId) => {
    try {
      // Fetch the file as a Blob from the backend
      const response = await axios.get(`/blob/${fileId}`, {
        responseType: "blob",
        headers: { withCredentials: true },
      });

      // Determine the MIME type from the response headers or default to a generic type
      const contentType = response.headers["content-type"] || "application/octet-stream";

      // Validate the MIME type against allowed types
      if (!allowedMimeTypes.includes(contentType)) {
        throw new Error("Unsupported file type");
      }

      // Determine the filename from the Content-Disposition header or use a default name
      const disposition = response.headers["content-disposition"];
      let filename = "downloaded_file";

      if (disposition && disposition.includes("filename=")) {
        const filenameMatch = disposition.match(/filename="?([^"]+)"?/);
        if (filenameMatch) {
          // Sanitize the filename to avoid potential XSS issues
          filename = filenameMatch[1].replace(/[^a-zA-Z0-9._-]/g, "_");
        }
      }

      // Create a Blob from the response data
      const blob = new Blob([response.data], { type: contentType });

      // Create a URL for the Blob
      const url = window.URL.createObjectURL(blob);

      if (contentType === "application/pdf") {
        // For PDF, open the Blob URL in a new tab for preview
        const newWindow = window.open(url, "_blank");

        if (newWindow) {
          // Ensure the new window is opened
          newWindow.addEventListener("load", () => {
            // Add a download link to the new tab
            const downloadLink = newWindow.document.createElement("a");
            downloadLink.href = url;
            downloadLink.download = filename;
            downloadLink.textContent = "Download this file";
            downloadLink.className = "download-link";
            newWindow.document.body.appendChild(downloadLink);

            // Cleanup: revoke the URL after a delay
            setTimeout(() => {
              window.URL.revokeObjectURL(url);
            }, 10000); // Adjust the timeout as needed
          });
        } else {
          console.error("Failed to open new window. Pop-up might be blocked.");
          window.URL.revokeObjectURL(url);
        }
      } else {
        // For other file types, directly trigger the download
        const link = document.createElement("a");
        link.href = url;
        link.download = filename;
        document.body.appendChild(link);
        link.click();
        link.remove();

        // Revoke the Blob URL after the download is initiated
        window.URL.revokeObjectURL(url);
      }
    } catch (error) {
      console.error("Error fetching file content:", error);
    }
  };


  // Function to get the text description for the documentTypeCode
  const getDocumentTypeText = (code) => {
    const option = contentTypeOptions.find((opt) => opt.id === code);
    return option ? option.text : "Unbekannter Typ";
  };

  return (
    <div className="divCDDocumentContainer">
      <div className="hlDocumentUploader">Dokumente</div>
      <List>
        {uploadedFiles.length > 0 ? (
          uploadedFiles.map((doc) => (
            <ListItem key={doc.id} className="documentList">
              <ListItemIcon className="documentListItem">
                <InsertDriveFileOutlinedIcon />
              </ListItemIcon>
              <ListItemText
                primary={`${doc.filename} (${getDocumentTypeText(doc.documentTypeCode)})`}
                onClick={() => handleFileClick(doc.id)} // Handle file click to open in new tab
                className="clickableFile" // Add a class for styling the clickable item if needed
              />
              <IconButton className="documentListDeleteButton" onClick={() => handleConfirmDelete(doc.id)}>
                <ClearOutlinedIcon />
              </IconButton>
            </ListItem>
          ))
        ) : (
          <ListItem>
            <ListItemText primary="Keine Dokumente vorhanden" />
          </ListItem>
        )}
      </List>

      <input type="file" className="inputFieldFileUpload" ref={fileInputRef} onChange={handleFileChange} />
      <div className="fileUploadContainer">
        <FormControl variant="standard" size="small" className="docFormControls">
          <InputLabel id="selectItems">Dokument Typ</InputLabel>
          <Select labelId="selectItems" value={selectedContentType} onChange={(e) => setSelectedContentType(e.target.value)}>
            <MenuItem id="selectItemsContentType" value="">
              <em>Bitte Dokument Typ auswählen</em>
            </MenuItem>
            {contentTypeOptions.map((option) => (
              <MenuItem key={option.id} value={option.id}>
                {option.text}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Button className="cmdAddDocument" size="small" startIcon={<FileUploadOutlinedIcon />} onClick={handleButtonClick}>
          Dokument hinzufügen
        </Button>
        <br />
        <span className="fileSizeNote">(Notiz: Maximale Dateigröße 10MB)</span>
      </div>

      {/* Confirmation dialog for file deletion */}
      <Dialog open={openConfirmDialog} onClose={() => setOpenConfirmDialog(false)}>
        <DialogTitle>Löschen</DialogTitle>
        <DialogContent>
          <DialogContentText>Möchten Sie wirklich die Datei löschen?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenConfirmDialog(false)} color="primary">
            Abbrechen
          </Button>
          <Button onClick={handleDeleteFile} color="primary">
            Löschen
          </Button>
        </DialogActions>
      </Dialog>

      {/* Dialog for "file already exists" alert */}
      <Dialog open={fileExists} onClose={handleCloseFileExistsDialog}>
        <DialogTitle>Datei bereits vorhanden</DialogTitle>
        <DialogContent>
          <DialogContentText>Diese Datei wurde bereits hochgeladen</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseFileExistsDialog} color="primary">
            Schließen
          </Button>
        </DialogActions>
      </Dialog>

      {/* Dialog for file size error */}
      <Dialog open={fileSizeError} onClose={handleCloseFileSizeErrorDialog}>
        <DialogTitle>Dateigröße überschritten</DialogTitle>
        <DialogContent>
          <DialogContentText>Die Dateigröße überschreitet 10 MB</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseFileSizeErrorDialog} color="primary">
            Schließen
          </Button>
        </DialogActions>
      </Dialog>

      {/* Dialog for content type error */}
      <Dialog open={contentTypeError.open} onClose={handleCloseContentTypeErrorDialog}>
        <DialogTitle>Fehler</DialogTitle>
        <DialogContent>
          <DialogContentText>{contentTypeError.message}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseContentTypeErrorDialog} color="primary">
            Schließen
          </Button>
        </DialogActions>
      </Dialog>

      {/* Snackbar for file deletion success */}
      <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity="success">
          Datei erfolgreich gelöscht!
        </Alert>
      </Snackbar>
    </div>
  );
}

export default CaseDetailDocumentContainer;
