import React from "react";
import OnlineFormData from "./OnlineFormData";
import OnlineFormComment from "./OnlineFormComment";
import OnlineFormDocument from "./OnlineFormDocument";
import OnlineFormHeader from "./OnlineFormHeader";


function OnlineExternal() {
  return (
      <div className="divOnlineFormContainer">
        <OnlineFormHeader/>
        <OnlineFormData/>
        {/*<OnlineFormComment/>*/}
        <OnlineFormDocument/>
      </div>
  );
}

export default OnlineExternal;